<template>
  <div :class="containerType">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ContainerElement",
  props: {
    containerType: {
      type: String,
      default: "base-container",
      validator: function (value) {
        return [
          "base-container",
          "nav-container",
          "footer-container",
          "full-container",
        ].includes(value);
      },
    },
  },
};
</script>

<style>
.base-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90vw;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .base-container {
    max-width: 85%;
  }
}
@media (min-width: 1024px) {
  .base-container {
    max-width: min(85%, 1200px);
  }
}
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.full-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
}

</style>
