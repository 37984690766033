<template>
  <a :href="link" name="Portal Clientes" class="button">{{ label }}</a>
</template>
<script>
export default {
  name: "LinkButton",
  props: {
    link: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.button {
  background-color: #333333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
a {
  text-decoration: none;
  color: #000;
}
.button:hover {
  background-color: #000;
}
</style>
