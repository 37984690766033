<template>
  <section>
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: "SectionElement",
};
</script>

<style>
section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>