<template>
  <footer>Copyright© Scrap Free 2024 | Todos los derechos reservados</footer>
</template>
<script>
export default {
  name: "HomeFooter",
};
</script>
<style scoped>
footer {
  width: 100%;
  color: white;
  background-color: #333333;
  text-align: center;
  padding: 30px 0;
  font-size: 0.9rem;
}
</style>
