<template>
  <SectionElement>
    <ContainerElement>
      <div class="home-cards-section">
        <div class="home-cards-section-content">
          <h2>¿Por qué elegir Scrap Free?</h2>
          <p>Gestión de siniestros sin preocupaciones y un servicio excepcional</p>
        </div>
        <div class="flex flex-col gap-y-10">
          <HomeCard imagePath="/home/ahorro_tiempo.png">
            <template #title>
              <b>Prevención del Fraude y Eficiencia Operativa:</b> Nuestro Valor para las
              Compañías de Seguros
            </template>
            <template #description>
              ScrapFree, aliado clave para aseguradoras, ofrece una sólida defensa contra
              el fraude. Con una amplia red de peritos, garantizamos inspecciones precisas
              y rápidas, agilizando el proceso de liquidación y minimizando el riesgo de
              fraude.
            </template>
          </HomeCard>
          <HomeCard imagePath="/home/antifraude.png">
            <template #title>
              <b>Ahorro de Tiempo y Gestión Efectiva:</b> Beneficios Tangibles para las
              Compañías Aseguradoras
            </template>
            <template #description>
              ScrapFree ofrece a las aseguradoras un soporte eficiente en la gestión de
              casos, liberándolas de tareas operativas y logísticas. Nuestro enfoque
              integral ahorra tiempo y previene futuras denuncias al retirar equipos tras
              el pago.
            </template>
          </HomeCard>
          <HomeCard imagePath="/home/eficiencia_operativa.png">
            <template #title>
              <b>Eficiencia Operativa y Visión Futurista:</b> Beneficios para las
              Compañías de Seguros
            </template>
            <template #description>
              ScrapFree va más allá de la liquidación tradicional de siniestros. Con
              visión futurista y tecnología avanzada, agilizamos procesos, permitimos
              manejar grandes volúmenes con eficacia mejorando la calidad y tiempos de
              respuesta.
            </template>
          </HomeCard>
        </div>
      </div>
    </ContainerElement>
  </SectionElement>
</template>
<script>
import HomeCard from "@/components/home/HomeCard.vue";
import ContainerElement from "@/components/home/ContainerElement.vue";
import SectionElement from "@/components/home/SectionElement.vue";

export default {
  name: "HomeCards",
  components: { HomeCard, ContainerElement, SectionElement },
  props: {},
};
</script>
<style scoped>
.home-cards-section-content h2 {
  font-size: 3.5rem;
  font-weight: 400;
  color: #29445c;
  margin: 0;
  text-align: center;
}

.home-cards-section-content p {
  font-size: 1.8rem;
  color: #333333;
  text-align: center;
}
.home-cards-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
  gap: 5rem;
}
.home-cards-section-content::after {
  content: "";
  display: block;
  width: 180px;
  height: 8px;
  background-color: #29445c;
  margin: -1rem auto;
}
</style>
