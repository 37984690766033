<template>
  <SectionElement>
    <div
      class="main-banner"
      :style="{ backgroundImage: 'url(' + require(`@/assets${imagePath}`) + ')' }"
    >
      <ContainerElement class="items-start">
        <h1>{{ title }}</h1>
        <p>{{ description }}</p>
        <LinkButton v-if="link" :link="link" :label="label" />
      </ContainerElement>
    </div>
  </SectionElement>
</template>
<script>
import LinkButton from "@/components/home/LinkButton.vue";
import ContainerElement from "@/components/home/ContainerElement.vue";
import SectionElement from "@/components/home/SectionElement.vue";
export default {
  name: "HomeBanner",
  components: {
    LinkButton,
    ContainerElement,
    SectionElement,
  },
  props: {
    link: {
      type: String,
      default: "",
    },
    imagePath: {
      type: String,
      default: "/home/banner-image.png",
    },
    title: {
      type: String,
      default: "Scrap Free",
    },
    description: {
      type: String,
      default:
        "Excelencia y eficiencia en seguros. Simplificamos la liquidación de siniestros con innovación tecnológica.",
    },
  },
};
</script>
<style scoped>

h1 {
  font-size: 3.5rem;
  color: white;
  font-weight: 400;
  text-align: center;
  margin: 20px 0 0;
}

p {
  font-size: 1.5rem;
  color: white;
  text-align: start;
  max-width: 500px;
}

.main-banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  padding: 20px 0;
  min-height: 300px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>
