<template>
  <div class="card-wrapper">
    <div class="card-image">
      <img :src="require(`@/assets${imagePath}`)" alt="Home card" />
    </div>
    <div class="card-content">
      <h3>
        <slot name="title"></slot>
      </h3>
      <p>
        <slot name="description"></slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeCard",
  props: {
    imagePath: {
      type: String,
      default: "/home/1_efficiency.png",
    },
  },
};
</script>

<style scoped>

h3 {
  font-size: 2rem;
  color: #29445c;
  margin: 0;
  font-weight: 400;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  margin: 0 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.card-wrapper:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.card-image {
  width: 100%;
  height: 280px;
  overflow: hidden;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  gap: 1.5rem;
}

.card-content h3 {
  font-size: 1.5rem;
  color: #29445c;
  margin: 0;
}

.card-content p {
  font-size: 1rem;
  color: #333333;
  margin: 0;
}

@media (min-width: 768px) {
  .card-wrapper {
    flex-direction: row;
    width: 100%;
  }

  .card-image {
    flex-grow: 1;
    max-width: 380px;
    height: 280px;
  }
  
  .card-content {
    width: 100%;
    padding: 1rem;
    text-align: start;
  }

  .card-content h3 {
    font-size: 1.8rem;
    color: #29445c;
    margin: 0;
  }

  .card-content p {
    font-size: 1.5rem;
    color: #333333;
    margin: 0;
  }
}

</style>
