<template>
  <SectionElement>
    <ContainerElement>
      <nav>
        <img alt="Scrap Free Logo" src="@/assets/logo.png" />
        <LinkButton link="#" label="Portal Clientes" />
      </nav>
    </ContainerElement>
  </SectionElement>
</template>
<script>
import LinkButton from "@/components/home/LinkButton.vue";
import ContainerElement from "@/components/home/ContainerElement.vue";
import SectionElement from "@/components/home/SectionElement.vue";
export default {
  name: "HomeNavbar",
  components: {
    LinkButton,
    ContainerElement,
    SectionElement,
  },
};
</script>
<style scoped>
nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
img {
  width: 204px;
  aspect-ratio: 204/56;
}
</style>
