<template>
  <SectionElement class="bg-light">
    <ContainerElement>
      <div class="home-contact-section">
        <div class="home-contact-section-content">
          <h2>Contáctenos</h2>
          <div>
            <p>Algunas provincias donde puedes encontrarnos:</p>
            <p class="text-gray-500">
              CABA - PROVINCIA DE BUENOS AIRES - SANTA FE - ENTRE RIOS - CORDOBA - MENDOZA
              - NEUQUEN - MISIONES - BAHIA BLANCA - SAN LUIS - SAN JUAN- SALTA - JUJUY -
              RIO NEGRO - CHUBUT
            </p>
          </div>
          <LinkButton link="#" label="Contacto" />
        </div>
      </div>
    </ContainerElement>
  </SectionElement>
</template>
<script>
import LinkButton from "@/components/home/LinkButton.vue";
import ContainerElement from "@/components/home/ContainerElement.vue";
import SectionElement from "@/components/home/SectionElement.vue";

export default {
  name: "HomeContact",
  components: {
    LinkButton,
    ContainerElement,
    SectionElement,
  },
  props: {},
};
</script>
<style scoped>
.home-contact-section-content h2 {
  font-size: 3.5rem;
  font-weight: 400;
  color: #29445c;
  margin: 0;
  text-align: center;
}

.home-contact-section-content p {
  font-size: 1.8rem;
  text-align: center;
}
.home-contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
  gap: 5rem;
}
.home-contact-section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-contact-section-content h2::after {
  content: "";
  display: block;
  width: 180px;
  height: 8px;
  background-color: #29445c;
  margin: 1rem auto;
}
</style>
