<template>
  <SectionElement class="bg-light">
    <ContainerElement>
      <div class="home-resume">
        <div class="home-resume-content">
          <h2>
            <b>ScrapFree</b>: Elevando la experiencia de siniestros a un nuevo nivel,
            excelencia y eficiencia en el ámbito de la liquidación de seguros.
          </h2>
          <p>
            En <b>ScrapFree</b>, estamos comprometidos con una gestión de siniestros
            excepcional. Nuestra propuesta de valor se basa en un
            <b>servicio de atención al cliente de primer nivel</b>, con expertos en
            seguros que brindan un soporte personalizado en cada etapa del proceso.
            Garantizamos una comunicación fluida y constante, brindando tranquilidad a los
            asegurados y estableciendo un <b>estándar de excelencia en la industria.</b>
          </p>
        </div>
        <div class="home-resume-image">
          <img
            src="@/assets/home/5_how_to_measure_customer_loyalty.png"
            alt="Customer Loyalty"
          />
        </div>
      </div>
    </ContainerElement>
  </SectionElement>
</template>
<script>
import ContainerElement from "@/components/home/ContainerElement.vue";
import SectionElement from "@/components/home/SectionElement.vue";
export default {
  name: "HomeResume",
  components: {
    ContainerElement,
    SectionElement,
  },
  props: {},
};
</script>
<style scoped>
.home-resume-content h2 {
  font-size: 2.5rem;
  font-weight: 400;
  color: #29445c;
  margin: 0;
}

.home-resume-content p {
  font-size: 1.5rem;
  color: #333333;
  text-align: start;
}

.home-resume {
  display: grid;
  grid-template-rows: auto auto;
  gap: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px 0;
  background: #f6f6f6;
}
.home-resume-image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.home-resume-image img {
  width: 100%;
  max-width: 300px;
  height: auto;
}

@media (min-width: 1024px) {
  .home-resume {
    text-align: start;
    grid-template-columns: 1fr minmax(300px, 500px);
    grid-template-rows: auto;
    gap: 40px;
  }
  .home-resume-content {
    grid-column: 1 / 2;
  }
  .home-resume-image {
    grid-column: 2 / 3;
  }
  .home-resume-image img {
    max-width: 450px;
  }
}
</style>
