<template>
  <div>
    <HomeNavbar />
    <HomeBanner
      imagePath="/home/banner-image.png"
      title="Scrap Free"
      description="Excelencia y eficiencia en seguros. Simplificamos la liquidación de siniestros con innovación tecnológica."
    />
    <HomeResume />
    <HomeCards />
    <HomeContact />
    <HomeFooter />
  </div>
</template>

<script>
import HomeNavbar from "@/views/HomeSections/HomeNavbar.vue";
import HomeBanner from "@/views/HomeSections/HomeBanner.vue";
import HomeResume from "@/views/HomeSections/HomeResume.vue";
import HomeCards from "@/views/HomeSections/HomeCards.vue";
import HomeContact from "@/views/HomeSections/HomeContact.vue";
import HomeFooter from "@/views/HomeSections/HomeFooter.vue";
export default {
  name: "Home-view",
  data() {
    return {};
  },
  components: {
    HomeNavbar,
    HomeBanner,
    HomeResume,
    HomeCards,
    HomeContact,
    HomeFooter,
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style>
.items-start {
  align-items: flex-start !important;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.gap-y-10 {
  gap: 2.5rem;
}

.text-gray-500 {
  color: #6b7280;
}

.w-full {
  width: 100%;
}
.bg-light {
  background-color: #f8f9fa;
}
</style>
